body {
  a.user-owner {
    color: var(--user-owner-color);
  }

  a.user-admin {
    color: var(--user-admin-color);
  }

  a.user-moderator {
    color: var(--user-moderator-color);
  }

  a.user-member {
    color: var(--user-member-color);
  }

  a.user-restricted {
    color: var(--user-restricted-color);
  }
}
